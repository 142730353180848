
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";

export default {
  name: "CriteriaTypeSelect",
  props: {
    criteria_type_id: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:criteria_type_id"],

  setup(props) {
    const { t } = useI18n();
    const inputCriteriaType = ref({
      loading: false,
      options: [
        "String",
        "Integer",
        "Range",
        "Object",
        "Boolean",
        "Float",
        "FloatPlus",
        "FloatTimes",
      ],
      list: [
        "String",
        "Integer",
        "Range",
        "Object",
        "Boolean",
        "Float",
        "FloatPlus",
        "FloatTimes",
      ],
    });

    const isTypeRequired = (value) => {
      if (props.required && !value) {
        return t("rcriteriatype");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "criteria_type",
      isTypeRequired
    );

    const selectCriteriaType = (query) => {
      if (query !== "") {
        inputCriteriaType.value.loading = true;
        setTimeout(() => {
          inputCriteriaType.value.loading = false;
          inputCriteriaType.value.options = inputCriteriaType.value.list.filter(
            (x) => {
              return x.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputCriteriaType.value.options = [
          "String",
          "Integer",
          "Range",
          "Object",
          "Boolean",
          "Float",
          "FloatPlus",
          "FloatTimes",
        ];
      }
    };

    watch(
      () => props.criteria_type_id,
      (first) => {
        element_id.value = first;
      }
    );

    return {
      element_id,
      inputCriteriaType,
      errorMessage,
      selectCriteriaType,
    };
  },
};
