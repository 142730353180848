
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "EntityTypesSelect",
  props: {
    multiselect: {
      type: Boolean,
      default: false,
    },
    entity_type_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:entity_type_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const element_id = ref("");
    const inputTypeEntities = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isBLRequired = (value) => {
      if (props.required && !value) {
        return t("rbl");
      }
      return true;
    };

    const { errorMessage, value } = useField("bl", isBLRequired);

    const getTypeEntities = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/type-entities/lists?per_page=1000").then(
        ({ data }) => {
          store.commit("setBLs", data.type_entities);
          inputTypeEntities.value.list = data.type_entities;
          inputTypeEntities.value.options = data.type_entities;
      });
    };
    const selectTypeEntities = (query) => {
      inputTypeEntities.value.loading = true;
      setTimeout(() => {
        inputTypeEntities.value.loading = false;
        inputTypeEntities.value.options = inputTypeEntities.value.list.filter(
          (item: Record<string, any>) => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
          }
        );
      }, 200);
    };

    watch(
      () => props.entity_type_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      element_id.value = props.entity_type_id;
      setTimeout(function () {
        getTypeEntities();
      }, 3000);
    });

    return {
      element_id,
      inputTypeEntities,
      errorMessage,
      selectTypeEntities,
    };
  },
};
