
import { defineComponent, onMounted, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import EntityTypesSelect from "@/components/catalogs-select/EntityTypesSelect.vue";
import CriteriaTypeSelect from "@/components/catalogs-select/CriteriaTypeSelect.vue";
import TableRelationshipSelect from "@/components/catalogs-select/TableRelationshipSelect.vue";
import QuotationSelect from "@/components/catalogs-select/QuotationSelect.vue";
import { useStore } from "vuex";

interface FormRateCriteria {
  id?: number;
  name: string;
  //real_name: string;
  type: string;
  table_name_relationship: string;
  quotation: string;
  entity_type_id?: number;
}

export default defineComponent({
  name: "RateCriteriaForm",
  components: {
    Form,
    Field,
    ErrorMessage,
    CriteriaTypeSelect,
    TableRelationshipSelect,
    QuotationSelect,
    EntityTypesSelect,
  },
  props: {
    entityId: String,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const sync_criterias = ref([]) as any;
    const form = ref<FormRateCriteria>({
      name: "",
      //real_name: "",
      type: "",
      table_name_relationship: "",
      quotation: "",
      entity_type_id: undefined,
    });

    const isRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    const isRealRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    onMounted(() => {
      if (props.entityId) {
        store.commit("setLoadingStatus", true);
        ApiService.get("/api/tariff_criterias/" + props.entityId).then(
          (result) => {
            form.value.id = result[1].id;
            form.value.name = result[1].name;
            //form.value.real_name = result[1].real_name;
            form.value.type = result[1].type;
            form.value.quotation = result[1].quotation;
            form.value.table_name_relationship =
              result[1].table_name_relationship;
            form.value.entity_type_id = result[1].entity_type_id;
          }
        );
        ApiService.get("/api/synchronize_criterias").then((data) => {
          sync_criterias.value = data.data;
          const field = sync_criterias.value.filter(
            (x) => x.criteria_id == form.value.id
          );
          form.value.quotation = field.length ? field[0].description : "";
          store.commit("setLoadingStatus", false);
        });
      }
    });

    const createElement = async (resetForm) => {
      try {
        store.commit("setLoadingStatus", true);
        const resp = (
          await ApiService.post("/api/tariff_criterias", form.value)
        ).data;
        emit("success", resp);
        resetForm();
        submitButton.value?.removeAttribute("data-kt-indicator");
      } catch (error) {
        store.commit("setLoadingStatus", false);
        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateElement = (resetForm) => {
      store.commit("setLoadingStatus", true);
      ApiService.put("/api/tariff_criterias/" + props.entityId, form.value)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = data;
          resetForm();
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const onSumbit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (form.value.id) {
          updateElement(resetForm);
        } else {
          createElement(resetForm);
        }
      }
    };

    return {
      submitButton,
      form,
      sync_criterias,
      onSumbit,
      isRequired,
      isRealRequired,
      updateElement,
      createElement,
    };
  },
});
