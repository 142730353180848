
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default {
  name: "TableRelationshipSelect",
  props: {
    table_name_relationship_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:table_name_relationship_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const inputTableRelationship = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isTableRelationshipRequired = (value) => {
      if (props.required && !value) {
        return t("rtablerelationship");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "table_name_relationship",
      isTableRelationshipRequired
    );

    const getTableRelationship = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/tables_tariffs").then(({ data }) => {
        inputTableRelationship.value.list = data;
        inputTableRelationship.value.options = data;
      });
    };

    const selectTableRelationship = (query) => {
      if (query !== "") {
        inputTableRelationship.value.loading = true;
        setTimeout(() => {
          inputTableRelationship.value.loading = false;
          inputTableRelationship.value.options =
            inputTableRelationship.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
        }, 200);
      } else {
        inputTableRelationship.value.options = [];
      }
    };

    watch(
      () => props.table_name_relationship_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      getTableRelationship();
      element_id.value = props.table_name_relationship_id;
    });

    return {
      element_id,
      inputTableRelationship,
      errorMessage,
      selectTableRelationship,
    };
  },
};
