
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  name: "QuotationSelect",
  props: {
    quotation_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:quotation_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const element_id = ref(undefined);
    const data = [
      "modo",
      "flujo",
      "origen",
      "pol",
      "pod",
      "destino",
      "mercancia",
      "unidades",
      "peso",
      "largo",
      "ancho",
      "alto",
      "cbn",
      "equipamiento",
      "onwh",
      "distancia",
      "tipo de transporte",
      "cliente",
      "imo",
      "perecedera",
    ];
    const inputQuotation = ref({
      loading: false,
      options: data as any,
      list: data as any,
    });

    /*const getQuotations = () => {
      store.commit("setLoading", false);
      ApiService.get(`/api/synchronize_criterias`).then(({ data }) => {
        inputQuotation.value.list = data;
        inputQuotation.value.options = data;
      });
    };*/
    const selectQuotation = (query) => {
      if (query !== "") {
        inputQuotation.value.loading = true;
        setTimeout(() => {
          inputQuotation.value.loading = false;
          inputQuotation.value.options = inputQuotation.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputQuotation.value.options = inputQuotation.value.list;
      }
    };

    watch(
      () => props.quotation_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      //getQuotations();
      setTimeout(function () {
        element_id.value = props.quotation_id;
      }, 500);
    });

    return {
      element_id,
      inputQuotation,
      selectQuotation,
    };
  },
};
